<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="12">
        <data-table
          :headers="headers"
          :items="obCollection.getModelList()"
          :options="pagination"
          :total="serverItemsLength"
          :last-page="serverLastPage"
          :loading="bLocalLoading"
          btn-action-path="cfecodes"
          btn-action-item-key-id="id"
          hide-btn-action-view
          is-route-name
          @delete="deleteItem"
          @update:sort-by="onSortBy"
          @update:sort-desc="onSortDesc"
          @update:options="onPagination"
          @update:page="onPage"
        >
          <template #[`item.actions`]="{ item }">
            <module-actions :value="item.id" @delete="deleteItem" />
          </template>
        </data-table>
      </v-col>
    </v-row>
    <cfecodes-form
      v-model="obItem"
      :open.sync="displayForm"
      :key="$route.params.id"
    />
  </v-col>
</template>

<script lang="ts">
import { Mixins, Component } from "vue-property-decorator";
import CfecodesMixin from "@/modules/cfecodes/mixins/CfecodesMixin";
import ActiveIcon from "@/components/common/ActiveIcon.vue";
import CfecodesForm from "@/modules/cfecodes/components/Form.vue";
import { DataTableHeader } from "vuetify";

@Component({
  components: {
    ActiveIcon,
    CfecodesForm,
  },
})
export default class CfecodesList extends Mixins(CfecodesMixin) {
  isList = true;

  onMounted() {
    const arHeaders: DataTableHeader[] = [
      { text: "form", value: "form" },
      { text: "line", value: "line" },
      { text: "description", value: "description" },
    ];
    this.setDTHeaders(arHeaders);

    this.index();
  }
}
</script>
